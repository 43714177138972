<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="orders && Object.keys(orders.data).length > 0">
        <template v-for="(data, index) in orders.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card>
              <b-row>
                <b-col md="9" sm="9">
                  <div>
                    <h4 class="text-primary">#{{ data.id }}</h4>
                    <b-card-text class="text-gray">
                        {{ data.created_at | formatDate }}
                    </b-card-text>
                    <h4>
                      {{data.username}}
                    </h4>
                    <p class="text-secondary mb-0">
                      <b-link :href="'https://wa.me/'+data.mobile" target="_blank">
                        {{data.mobile}}
                        <b-img
                            fluid
                            :src="require('@/assets/images/icons/whatsapp.png')"
                            alt="whatsapp"
                            width="15px"
                          />
                      </b-link>
                    </p>
                    <p class="text-secondary mb-0">
                      {{data.city.name}} - {{data.address_owner}} - {{data.address_details}}
                    </p>
                  </div>
                </b-col>
                <b-col md="3" sm="3" align-self="center">
                  <div class="text-center">
                    <h1 class="text-primary" v-if="data.payment_method.id == 1">
                      {{data.total_price}}
                      <small>{{$t('SAR')}}</small>
                    </h1>
                    <div>
                      {{data.payment_method.name}}
                    </div>
                    <div class="mt-1 mb-1">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="success"
                        @click="haveBeenDelivered(data.id)"
                      >
                        <template v-if="!isLoadingDelivered">
                          {{$t('have been delivered')}}
                        </template>
                        <template v-if="isLoadingDelivered">
                          <b-spinner small />
                        </template>
                      </b-button>
                    </div>
                  </div>
                </b-col>
                <b-col md="12" sm="12">
                  <hr>
                  <h4>{{$t('Items')}}</h4>
                  <template v-for="(orderItem, o_i) in data.order_items">
                    <p :key="o_i">
                      {{orderItem.orderable.name}} 
                      <b-badge
                        pill
                        :variant="'primary'"
                        class="mr-1 ml-auto"
                      >
                        {{ orderItem.count }}
                      </b-badge>
                    </p>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination v-if="orders" :limit="7" :data="orders" @pagination-change-page="getShippingZoneOrders"></pagination>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ShippingZonesShowOrders',
  directives: {
    Ripple,
  },
  data() {
    return {
      orders: null,
      isLoading: false,
      isLoadingDelivered: false,
    }
  },
  mounted() {
    this.getShippingZoneOrders();
  },
  methods: {
    getShippingZoneOrders(page = 1){
      this.isLoading = true
      useJwt.get('/shipping-zones/get-shipping-zone-orders',{params: {
          page: page,
          user_id: this.$store.state.user.id
      }})
      .then((response) => {
        // console.log(response.data.data)
        this.orders = response.data.data
        console.log(this.orders)
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    haveBeenDelivered(order_id) {
      this.isLoadingDelivered = true
      useJwt.post('/orders/add-order-history',{
        order_id: order_id,
        status_id: 9, // 9 = DELIVERED
        notes: '',
        store_id: this.$store.state.store.id
      })
      .then((response) => {
        this.isLoadingDelivered = false
        window.location.reload()
      })
      .catch(response => {
        this.isLoadingDelivered = false
        // console.log(response);
      });
    }
  }
}
</script>
